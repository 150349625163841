import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../Constants/Api";
import { addRequest } from "../../../IndexDB/indexDB";
import {
  PerPageFive,
  PerPageOneHundred,
  PerPageThen,
} from "../../../Constants/Glopalconstants";
import { getInitiativeAsync } from "../initiative";

const initialState = {
  settings: {},
  settingsLoading: false,
  settingsError: "",

  lanes: [],
  lanesLoading: true,
  lanesCount: 0,
  lanesPage: 0,
  lanesError: "",

  checkpoints: [],
  checkpointsLoading: true,
  checkpointsCount: 0,
  checkpointsPage: 0,
  checkpointStatus: null,
  checkpointsError: "",

  tags: [],
  tagsLoading: true,
  tagsError: "",

  impactTags: [],
  impactTagsLoading: true,

  effortTag: [],
  effortTagLoading: true,

  selectImpactOrEffortTags: null,
  selectImpactOrEffortTagsError: false,
};

export const getSettingsAsync = createAsyncThunk(
  "setting/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(`${BASE_URL}/organization/settings`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateSettingsAsync = createAsyncThunk(
  "setting/post",
  async (payload, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/organization/settings`,
      { ...payload },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getLanesAsync = createAsyncThunk(
  "lanes/get",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.get(
        `${BASE_URL}/lane/feedback?page=${action.page}&perPage=${PerPageThen}`,
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const createLaneAsync = createAsyncThunk(
  "createLane/post",
  async (action, state) => {
    try {
      const startTime = new Date().getTime();
      const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
      const response = await axios.post(
        `${BASE_URL}/lane`,
        { ...action },
        {
          headers: {
            "x-feedback": feedbackId,
          },
        }
      );
      const reqTime = new Date().getTime() - startTime;
      addRequest(response, reqTime);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return state.rejectWithValue(err.response.data);
    }
  }
);

export const deleteLaneAsync = createAsyncThunk(
  "deleteLane/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/lane/${action.id}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateLaneAsync = createAsyncThunk(
  "updateLane/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/lane/${action.id}`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getCheckpointsAsync = createAsyncThunk(
  "checkpoints/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/checkpoint/feedback/${action.initID}?page=${action.page}&perPage=${PerPageThen}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createCheckpointAsync = createAsyncThunk(
  "createCheckpoint/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/checkpoint`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteCheckpointAsync = createAsyncThunk(
  "deleteCheckpoint/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/checkpoint/${action.id}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateCheckpointAsync = createAsyncThunk(
  "updateCheckpoint/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/checkpoint/${action.id}`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getCheckpointCommentAsync = createAsyncThunk(
  "getCheckpointCommentAsync/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/checkpoint-comment/${action.id}/${action.initID}?page=${action.page}&perPage=${PerPageThen}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return { data: response.data, id: action.id };
  }
);

export const createCheckpointCommentAsync = createAsyncThunk(
  "createCheckpointComment/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/checkpoint-comment`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const checkCheckpointCommentAsync = createAsyncThunk(
  "checkCheckpointComment/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/checkpoint/check`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getTagsAsync = createAsyncThunk(
  "getTags/get",
  async (action, state) => {
    const user = state.getState().user?.user;
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(`${BASE_URL}/tags?orgID=${user.orgID}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const createTagAsync = createAsyncThunk(
  "createTag/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/tags`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateTagsAsync = createAsyncThunk(
  "updateTags/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/tags/${action.tagId}`,
      { ...action.updateData },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteTagAsync = createAsyncThunk(
  "deleteTag/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(`${BASE_URL}/tags/${action.tagId}`, {
      headers: {
        "x-feedback": feedbackId,
      },
    });
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const dragEntriesAsync = createAsyncThunk(
  "ragEntries/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/${action.name}/re-order-priority-index`,
      {
        id: action.id,
        priorityIndex: action.priorityIndex,
      },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getImpactGroupAsync = createAsyncThunk(
  "impactGroup/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/impact-effort-group?type=${action.type}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data?.tags;
  }
);

export const getEffortGroupAsync = createAsyncThunk(
  "effortGroup/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/impact-effort-group?type=${action.type}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data?.tags;
  }
);

export const createImpactOrEffortGroupAsync = createAsyncThunk(
  "createImpactOrEffortGroup/post",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.post(
      `${BASE_URL}/impact-effort-group`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const updateImpactOrEffortGroupAsync = createAsyncThunk(
  "updateImpactOrEffortGroup/patch",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.patch(
      `${BASE_URL}/impact-effort-group/${action.id}`,
      { ...action },
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const deleteImpactOrEffortGroupAsync = createAsyncThunk(
  "deleteImpactOrEffortGroup/delete",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.delete(
      `${BASE_URL}/impact-effort-group/${action.id}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

export const getImpactOrEffortGroupAsync = createAsyncThunk(
  "getImpactOrEffortGroup/get",
  async (action, state) => {
    const startTime = new Date().getTime();
    const feedbackId = state.getState().user?.selectedFeedback.feedbackID;
    const response = await axios.get(
      `${BASE_URL}/impact-effort-group/${action.id}`,
      {
        headers: {
          "x-feedback": feedbackId,
        },
      }
    );
    const reqTime = new Date().getTime() - startTime;
    addRequest(response, reqTime);
    return response.data;
  }
);

const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    resetSettings: () => initialState,
    emptySelectImpactOrEffortTags(state) {
      state.selectImpactOrEffortTags = null;
      state.selectImpactOrEffortTagsError = false;
    },
    addNewCheckpointComment(state, action) {
      const index = state.checkpoints.findIndex(
        (item) => item.id === action.payload.checkpointId
      );
      state.checkpoints[index].comments = [
        action.payload,
        ...state.checkpoints[index].comments,
      ];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSettingsAsync.pending, (state, action) => {
        state.settingsLoading = true;
      })
      .addCase(getSettingsAsync.fulfilled, (state, action) => {
        state.settings = {
          errorPeriod: action?.payload?.errorPeriod,
          warningPeriod: action?.payload?.warningPeriod,
        };
        state.settingsLoading = false;
      })
      .addCase(getSettingsAsync.rejected, (state, action) => {
        state.settingsLoading = false;
        state.settingsError = "Something went wrong";
      })
      .addCase(getLanesAsync.pending, (state, action) => {
        state.lanesLoading = true;
      })
      .addCase(getLanesAsync.fulfilled, (state, action) => {
        state.lanes = action.payload.lanes;
        state.lanesCount = action.payload.count;
        state.lanesPage = action.payload.page;
        state.lanesLoading = false;
      })
      .addCase(getLanesAsync.rejected, (state, action) => {
        state.lanesError = `${action.payload?.statusCode} - ${action.payload?.message}`;
        state.lanesLoading = false;
      })
      .addCase(createLaneAsync.fulfilled, (state, action) => {
        state.lanes = [action.payload, ...state.lanes];
        if ([action.payload, ...state.lanes].length > 11) {
          state.lanes.pop();
        }
        state.lanesCount += 1;
      })
      .addCase(deleteLaneAsync.fulfilled, (state, action) => {
        state.lanes = state.lanes.filter(
          (lane) => lane.id !== action.payload.id
        );
        state.lanesCount -= 1;
      })
      .addCase(getCheckpointsAsync.pending, (state) => {
        state.checkpointsLoading = true;
      })
      .addCase(getCheckpointsAsync.fulfilled, (state, action) => {
        state.checkpoints = action.payload.checkpoints;
        state.checkpointsCount = action.payload.count;
        state.checkpointsPage = action.payload.page;
        state.checkpointsLoading = false;
      })
      .addCase(getCheckpointsAsync.rejected, (state, action) => {
        state.checkpointsError = "Something went wrong";
        state.checkpointsLoading = false;
      })
      .addCase(createCheckpointAsync.fulfilled, (state, action) => {
        state.checkpoints = [action.payload, ...state.checkpoints];
        if ([action.payload, ...state.checkpoints].length > 11) {
          state.checkpoints.pop();
        }
        state.checkpointsCount += 1;
      })
      .addCase(deleteCheckpointAsync.fulfilled, (state, action) => {
        state.checkpoints = state.checkpoints.filter(
          (checkpoint) => checkpoint.id !== action.payload.id
        );
        state.checkpointsCount -= 1;
      })
      .addCase(getCheckpointCommentAsync.pending, (state, action) => {})
      .addCase(getCheckpointCommentAsync.fulfilled, (state, action) => {
        const index = state.checkpoints.findIndex(
          (item) => item.id === action.payload.id
        );
        state.checkpoints[index].comments = [
          ...state.checkpoints[index].comments,
          ...action.payload.data.comments,
        ];
        state.checkpoints[index].page = action.payload.data.page;
      })
      .addCase(checkCheckpointCommentAsync.fulfilled, (state, action) => {
        state.checkpointStatus = action.payload.checkpointStatus;
      })
      .addCase(getTagsAsync.pending, (state, action) => {
        state.tagsLoading = true;
      })
      .addCase(getTagsAsync.fulfilled, (state, action) => {
        state.tags = action.payload;
        state.tagsLoading = false;
      })
      .addCase(getTagsAsync.rejected, (state, action) => {
        state.tagsError = "Something went wrong";
        state.tagsLoading = false;
      })
      .addCase(createTagAsync.fulfilled, (state, action) => {
        state.tags = [action?.payload?.group, ...state.tags];
      })
      .addCase(updateTagsAsync.fulfilled, (state, action) => {
        const index = state.tags.findIndex(
          (item) => item.id === action.payload.group.id
        );
        state.tags[index] = action.payload.group;
      })
      .addCase(deleteTagAsync.fulfilled, (state, action) => {
        state.tags = state.tags.filter((tag) => tag.id !== action.payload.id);
      })
      .addCase(getImpactGroupAsync.pending, (state, action) => {
        state.impactTagsLoading = true;
      })
      .addCase(getImpactGroupAsync.fulfilled, (state, action) => {
        state.impactTagsLoading = false;
        state.impactTags = action.payload;
      })
      .addCase(getImpactGroupAsync.rejected, (state, action) => {
        state.impactTagsLoading = false;
      })
      .addCase(getEffortGroupAsync.pending, (state, action) => {
        state.effortTagLoading = true;
      })
      .addCase(getEffortGroupAsync.fulfilled, (state, action) => {
        state.effortTagLoading = false;
        state.effortTag = action.payload;
      })
      .addCase(getEffortGroupAsync.rejected, (state, action) => {
        state.effortTagLoading = false;
      })
      .addCase(getImpactOrEffortGroupAsync.fulfilled, (state, action) => {
        state.selectImpactOrEffortTags = action.payload;
      })
      .addCase(getImpactOrEffortGroupAsync.rejected, (state, action) => {
        state.selectImpactOrEffortTagsError = "Something went wrong";
      })
      .addCase(getInitiativeAsync.fulfilled, (state, action) => {
        state.checkpointStatus = action.payload?.checkpointStatus;
      });
  },
});

export const {
  resetSettings,
  emptySelectImpactOrEffortTags,
  addNewCheckpointComment,
} = settingSlice.actions;

//Selectors
export const getSettings = (state) => state.setting.settings;
export const getSettingsLoading = (state) => state.setting.settingsLoading;
export const getSettingsError = (state) => state.setting.settingsError;

export const getLanes = (state) => state.setting.lanes;
export const getLanesLoading = (state) => state.setting.lanesLoading;
export const getLanesCount = (state) => state.setting.lanesCount;
export const getLanesPage = (state) => state.setting.lanesPage;
export const getLanesError = (state) => state.setting.lanesError;

export const getCheckpoints = (state) => state.setting.checkpoints;
export const getCheckpointsLoading = (state) =>
  state.setting.checkpointsLoading;
export const getCheckpointsCount = (state) => state.setting.checkpointsCount;
export const getCheckpointsPage = (state) => state.setting.checkpointsPage;
export const getCheckpointStatus = (state) => state.setting.checkpointStatus;
export const getCheckpointsError = (state) => state.setting.checkpointsError;

export const getTags = (state) => state.setting.tags;
export const getTagsLoading = (state) => state.setting.tagsLoading;
export const getTagsError = (state) => state.setting.tagsError;

export const getImpactTags = (state) => state.setting.impactTags;
export const getImpactTagsLoading = (state) => state.setting.impactTagsLoading;

export const getEffortTag = (state) => state.setting.effortTag;
export const getEffortTagLoading = (state) => state.setting.effortTagLoading;
export const getSelectImpactOrEffortTags = (state) =>
  state.setting.selectImpactOrEffortTags;
export const getSelectImpactOrEffortTagsError = (state) =>
  state.setting.selectImpactOrEffortTagsError;

export default settingSlice.reducer;
