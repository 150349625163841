import parse from "html-react-parser";
import moment from "moment";
import deepcopy from "deepcopy";

const checkValidation = (object) => {
  let errors = {};
  let error = false;
  for (const property in object) {
    if (typeof object[property] === "string" && !object[property].trim()) {
      errors[property] = true;
      error = true;
    }
    if (typeof object[property] === "object" && !object[property].length) {
      errors[property] = true;
      error = true;
    }
  }
  return { errors, error };
};

const filterOrAddItem = ({ item, oldData }) => {
  if (
    oldData?.some(
      (data) =>
        (data?.id ||
          data?.answerID ||
          data.departmentID ||
          data.emailAddress) ===
        (item?.id || item?.answerID || item.departmentID || item.emailAddress)
    )
  ) {
    return oldData.filter(
      (data) =>
        (data?.id ||
          data?.answerID ||
          data.departmentID ||
          data.emailAddress) !==
        (item?.id || item?.answerID || item.departmentID || item.emailAddress)
    );
  } else {
    return [...oldData, item];
  }
};

const getHighlightedText = ({ keyword, input }) => {
  if (keyword && keyword.length > 0) {
    let searched = keyword.trim();
    let text = input;

    const regex = new RegExp(
      `(<)(script[^>]*>[^<]*(?:<(?!\\/script>)[^<]*)*<\\/script>|\\/?\\b[^<>]+>|!(?:--\\s*(?:(?:\\[if\\s*!IE]>\\s*-->)?[^-]*(?:-(?!->)-*[^-]*)*)--|\\[CDATA[^\\]]*(?:](?!]>)[^\\]]*)*]])>)|(${searched})`,
      "gi"
    );
    const highlight = text.replace(regex, function (match, p1, p2, p3) {
      return p3 ? "<mark>" + p3 + "</mark>" : match;
    });

    return parse(highlight);
  } else {
    return parse(input);
  }
};

const getMilestone = (milestones, date) => {
  return milestones.find(
    (item) => new Date(item.date).getMonth() === new Date(date).getMonth()
  )?.milestones;
};

const getPageContentByKey = ({ content, defaultPage, key }) => {
  return content[key] ? content[key] : defaultPage;
};

const strongCopyData = (data) => data && deepcopy(data);

const tableSort = (data, name, type) => {
  return type === "up"
    ? data.sort((a, b) => (a[name] < b[name] ? 1 : b[name] < a[name] ? -1 : 0))
    : data.sort((a, b) => (a[name] > b[name] ? 1 : b[name] > a[name] ? -1 : 0));
};

const triggerClassName = (param1, param2, className) => {
  if (param1 === param2) {
    return className;
  }
  return "";
};

const getClassNameByParam = (param, className) => {
  if (param) {
    return className;
  }
  return "";
};

const generateObjectsNumbersArray = (numbers) => {
  const arr = [];
  [...Array(numbers).keys()].map((number) =>
    arr.push({
      title: number + 1,
    })
  );
  return arr;
};

const changeTimeFormat = (time) => {
  if (!time) {
    return;
  }
  time = moment(time).fromNow();

  if (time === "a few seconds ago" || time === "in a few seconds") {
    return "a second ago";
  } else if (time.endsWith(" seconds ago")) {
    return "a second ago";
  } else if (time === "a minute ago") {
    return "1min ago";
  } else if (time.endsWith(" minutes ago")) {
    return time.replace(" minutes ago", " m ago");
  } else if (time === "an hour ago") {
    return "1h ago";
  } else if (time.endsWith(" hours ago")) {
    return time.replace(" hours ago", " h ago");
  } else if (time === "a day ago") {
    return "1d ago";
  } else if (time.endsWith(" days ago")) {
    return time.replace(" days ago", " d ago");
  } else if (time === "a month ago") {
    return "1mon ago";
  } else if (time.endsWith(" months ago")) {
    return time.replace(" months ago", " m ago");
  } else if (time === "a year ago") {
    return "1y";
  } else if (time.endsWith(" years ago")) {
    return time.replace(" years ago", " y ago");
  } else {
    return moment(time).fromNow();
  }
};

const makeUppercaseFirstLetter = (word) => {
  if (word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
};

const filterSameInitiativesOnTags = ({ selectTags, initiativesOnTags }) => {
  let data = [];
  selectTags?.forEach((selectTag) => {
    const item = initiativesOnTags?.find(
      (tag) =>
        (tag.id || tag.emailAddress) ===
        (selectTag.id || selectTag.emailAddress)
    );
    data = [...data, ...item?.initiatives];
  });

  const newGoalData = new Set(data);
  return [...newGoalData];
};

const sortNpsByYears = (nps) => {
  if (nps.length) {
    const data = [];
    let sortData = [];
    let startYear = moment(nps[0].date).format("YYYY");

    nps.forEach((el) => {
      const year = moment(el.date).format("YYYY");
      if (startYear === year) {
        sortData.push(el);
      } else {
        data.push({ year: startYear, sortData });
        sortData = [];
        sortData.push(el);
        startYear = year;
      }
    });
    return sortData.length ? [...data, { year: startYear, sortData }] : data;
  }
  return [];
};

const generateMatrix = (columns, rows) => {
  const matrix = [];

  for (let i = 0; i < columns; i++) {
    let row = [];
    for (let j = 0; j < rows; j++) {
      row.push([]);
    }
    matrix.push(row);
    row = [];
  }

  return matrix;
};

const getLetterByIndex = (index) => {
  return [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ][index];
};

const getPaginationDataAfterDeleteOrCreate = ({
  numberOfPages,
  currentPageDataCount,
  currentPage,
}) => {
  if (currentPage < numberOfPages && numberOfPages > 1) {
    return { page: currentPage };
  }

  if (currentPageDataCount === 0 && numberOfPages > 1) {
    return { page: currentPage - 1 };
  }

  return { page: null };
};

const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

const findAndReturnWordOfArrayInString = (wordArr, string) => {
  return string.split("/").find((str) => wordArr.includes(str));
};

const randomColor = () => {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
};

function secondsToHM(s) {
  const hours = Math.floor((s % (3600 * 24)) / 3600);
  const minutes = Math.floor((s % 3600) / 60);
  const seconds = Math.floor((s % 3600) % 60);
  if (hours && minutes) {
    return `${hours} hours ${minutes} minutes`;
  }
  if (minutes || seconds > 30) {
    return `${minutes || 1} minutes`;
  }
  return `No login`;
}

export {
  checkValidation,
  filterOrAddItem,
  getHighlightedText,
  getMilestone,
  getPageContentByKey,
  strongCopyData,
  tableSort,
  triggerClassName,
  getClassNameByParam,
  changeTimeFormat,
  makeUppercaseFirstLetter,
  filterSameInitiativesOnTags,
  sortNpsByYears,
  generateObjectsNumbersArray,
  generateMatrix,
  getLetterByIndex,
  getPaginationDataAfterDeleteOrCreate,
  validateEmail,
  findAndReturnWordOfArrayInString,
  randomColor,
  secondsToHM,
};

const HOUR_IN_MIN = 60;
const MIN_IN_SEC = 60;
const DAY_IN_SEC = 24 * HOUR_IN_MIN * MIN_IN_SEC;
const SEC_IN_MS = 1000;
const DAY_IN_MS = DAY_IN_SEC * SEC_IN_MS;

const timestamp = new Date().getTime();
const expire = timestamp + 10 * DAY_IN_MS;
export const expireDate = new Date(expire);
